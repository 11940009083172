import * as React from 'react';
import { useEffect, useState, FC } from 'react';
import './index.css'; // Import the CSS file for styling

interface TokenVolumeTableProps {
  onSymbolSelect: (symbol: string) => void;
}

export const TokenVolumeTable: FC<TokenVolumeTableProps> = ({ onSymbolSelect }) => {
  const [tokens, setTokens] = useState<{ token: string; volume: string }[]>([]);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await fetch('https://core-api.prismlife.xyz/v1/tokens');
        const data = await response.json();
        setTokens(data);
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    };

    fetchTokens();
  }, []);

  return (
    <table className="token-volume-table">
      <thead>
        <tr>
          <th>Token</th>
          <th>Volume</th>
        </tr>
      </thead>
      <tbody>
        {tokens.map((tokenData, index) => (
          <tr 
            key={index} 
            onClick={() => onSymbolSelect(tokenData.token)}
            className="token-row"
          >
            <td>{tokenData.token.slice(0, 5)}</td>
            <td>{Math.floor(Number(tokenData.volume))}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
