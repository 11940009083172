import * as React from 'react'
import { useEffect, useState } from 'react'

export const Table = ({ children, className = '', ...props }: React.HTMLAttributes<HTMLTableElement>) => (
  <table 
    className={`w-full border-collapse border-spacing-0 ${className}`}
    {...props}
  >
    {children}
  </table>
);

export const TableHeader = ({ children, className = '', ...props }: React.HTMLAttributes<HTMLTableSectionElement>) => (
  <thead 
    className={`bg-gray-100 ${className}`}
    {...props}
  >
    {children}
  </thead>
);

export const TableBody = ({ children, className = '', ...props }: React.HTMLAttributes<HTMLTableSectionElement>) => (
  <tbody 
    className={`${className}`}
    {...props}
  >
    {children}
  </tbody>
);

export const TableRow = ({ children, className = '', ...props }: React.HTMLAttributes<HTMLTableRowElement>) => (
  <tr 
    className={`border-b border-gray-200 ${className}`}
    {...props}
  >
    {children}
  </tr>
);

export const TableHead = ({ children, className = '', ...props }: React.ThHTMLAttributes<HTMLTableCellElement>) => (
  <th 
    className={`h-12 px-4 text-left align-middle font-medium ${className}`}
    {...props}
  >
    {children}
  </th>
);

export const TableCell = ({ children, className = '', ...props }: React.TdHTMLAttributes<HTMLTableCellElement>) => (
  <td 
    className={`p-4 align-middle ${className}`}
    {...props}
  >
    {children}
  </td>
);
interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {
    maxHeight?: string | number;
  }
  
  export const ScrollArea = ({ 
    children, 
    className = '', 
    maxHeight = '400px',
    ...props 
  }: ScrollAreaProps) => (
    <div 
      className={`overflow-auto ${className}`}
      style={{ 
        maxHeight,
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1'
      }}
      {...props}
    >
      {children}
    </div>
  );

interface Sale { 
    blockTimestamp: string
  slot: number 
  priceSol: string
  quantity:string 
  takerAddress: string  
  side: 'BUY' | 'SELL'
  txSig: string
}

const formatTimeAgo = (timestampRaw: string) => {
    const timestamp = new Date(timestampRaw).getTime()
  const now = Date.now(); // current time in seconds
  const secondsAgo = Math.floor((now - timestamp) / 1000);
  
  if (secondsAgo < 60) {
    return `${secondsAgo}s ago`;
  } else if (secondsAgo < 3600) {
    const minutes = Math.floor(secondsAgo / 60);
    return `${minutes}m ago`;
  } else if (secondsAgo < 86400) {
    const hours = Math.floor(secondsAgo / 3600);
    return `${hours}h ago`;
  } else {
    const days = Math.floor(secondsAgo / 86400);
    return `${days}d ago`;
  }
};

export const SalesTable = ({ symbol }: { symbol: string }) => {
  const [sales, setSales] = useState<Sale[]>([])

  useEffect(() => {
    // Simulating live data updates
    const interval = setInterval(() => {
        const fetchSales = async () => {
            try {
                const response = await fetch(`https://core-api.prismlife.xyz/v1/tokens/${symbol}/transactions`);
                const {transactions} = await response.json();
                console.log(transactions)
                setSales(transactions)
            } catch (error) {
                console.error('Error fetching tokens:', error);
            }
        };

        fetchSales();
    }, 1000)

    return () => clearInterval(interval)
  }, [symbol])

  return (
    <ScrollArea className="h-full w-full">
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>USD</TableHead>
            <TableHead>Token</TableHead>
            <TableHead>Sol</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Maker</TableHead>
            <TableHead>Transaction</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sales.map((sale) => (
            <TableRow key={sale.slot}>
              <TableCell>{formatTimeAgo(sale.blockTimestamp)}</TableCell>
              <TableCell style={{ color: sale.side === 'SELL' ? 'red' : 'green' }}>{sale.side}</TableCell>
              <TableCell style={{ color: sale.side === 'SELL' ? 'red' : 'green' }}>{(parseFloat(sale.priceSol) * parseFloat(sale.quantity) * 200).toFixed(2)}</TableCell>
              <TableCell style={{ color: sale.side === 'SELL' ? 'red' : 'green' }}>{parseFloat(sale.quantity).toFixed(2)}</TableCell>
              <TableCell style={{ color: sale.side === 'SELL' ? 'red' : 'green' }}>{(parseFloat(sale.quantity) * parseFloat(sale.priceSol)).toFixed(2)}</TableCell>
              <TableCell style={{ color: sale.side === 'SELL' ? 'red' : 'green' }}>{parseFloat(sale.priceSol).toFixed(8)}</TableCell>
              <TableCell>
                <a 
                  href={`https://solscan.io/account/${sale.takerAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700"
                >
                  {sale.takerAddress.slice(0, 4)}
                </a>
              </TableCell>
              <TableCell>
                <a 
                  href={`https://solscan.io/tx/${sale.txSig}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700"
                >
                  {sale.txSig.slice(0, 4)}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}