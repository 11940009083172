import { useEffect, useRef } from 'react';
import './index.css';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	ResolutionString,
} from '../../charting_library';
import * as React from 'react';

export interface ChartContainerProps {
	symbol: string;
	interval: ChartingLibraryWidgetOptions['interval'];

	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
}

const getLanguageFromURL = (): LanguageCode | null => {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
};

export const TVChartContainer = ({ symbol }: { symbol: string }) => {
	const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const tvWidgetRef = useRef<any>(null);

	const defaultProps: Omit<ChartContainerProps, 'container'> = {
		symbol: symbol,
		interval: '1S' as ResolutionString,
		datafeedUrl: 'https://core-api.prismlife.xyz/v1/udf',
		libraryPath: '/charting_library/',
		chartsStorageUrl: undefined,
		chartsStorageApiVersion: undefined,
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	useEffect(() => {
		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: defaultProps.symbol as string,
			// BEWARE: no trailing slash is expected in feed URL
			// tslint:disable-next-line:no-any
			datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl, 1000),
			interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath as string,

			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates', 'seconds_resolution'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
			debug: false,
		};

		const tvWidget = new widget(widgetOptions);
		tvWidgetRef.current = tvWidget;

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
			});
		});

		return () => {
			tvWidget.remove();
		};
	}, []);

	useEffect(() => {
		console.log('Symbol changed to:', symbol);
		if (tvWidgetRef.current) {
			try {
				// Wait for chart to be ready before setting symbol
				tvWidgetRef.current.onChartReady(() => {
					console.log('resetting');
					tvWidgetRef.current.chart().setSymbol(symbol);
					tvWidgetRef.current.chart().resetData()
				});
			} catch (error) {
				console.error('Error setting symbol:', error);
			}
		}
	}, [symbol]);

	return (
		<div
			ref={ chartContainerRef }
			className={ 'TVChartContainer' }
		/>
	);
};
