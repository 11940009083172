import * as React from 'react';
import './App.css';
import { TVChartContainer } from './components/TVChartContainer/index';
import { TokenVolumeTable } from './components/TokenVolumeTable/index';
import { SalesTable } from './components/SalesTable/index';
import { useState } from 'react';

const App = () => {
	const [selectedSymbol, setSelectedSymbol] = useState<string>('EkgmXgTWJRMoTDCR3wV5Uoxfn5vvUjaRMfGhx4ivXBVv');

	return (
		<div className="flex h-screen" style={{ display: 'flex' }}>
			<div className="w-[300px] border-r" style={{ flex: 1 }}>
				<TokenVolumeTable onSymbolSelect={setSelectedSymbol} />
			</div>
			<div style={{ flex: 7 }}>
				    {/* Chart takes up 75% of the height */}
					<div className="h-[75%]">
      <TVChartContainer symbol={selectedSymbol} />
    </div>
    
    {/* Sales Table takes up 25% of the height and full width */}
    <div className="h-[25%] border-t">
      <div className="h-full w-full">
        <SalesTable symbol={selectedSymbol} />
      </div>
    </div>
			</div>
		</div>
	);
};

export default App;
